import type { NavigationTab } from '@/types'

const Routes: NavigationTab[] = [
  {
    tabName: 'industries',
    sections: [
      {
        column: 'left',
        title: 'for_whom',
        links: [
          {
            to: '/industries/insurance',
            title: 'insurers',
            description: 'insurers',
            image: 'navi_icon_02',
          },
          {
            to: '/industries/public-health-and-government',
            title: 'government',
            description: 'insurers',
            image: 'navi_icon_public_health',
          },
          {
            to: '/industries/providers',
            title: 'providers',
            description: 'providers',
            image: 'navi_icon_01',
          },
          {
            to: '/industries/telemedicine',
            title: 'telemedicine',
            description: 'telemedicine',
            image: 'navi_icon_03',
          },
        ],
      },
    ],
  },
  {
    tabName: 'platform',
    sections: [
      {
        column: 'left',
        title: 'our_platform',
        links: [
          {
            title: 'platform',
            to: '/platform',
            description: 'platform',
            image: 'navi_icon_04',
          },
        ],
      },
      {
        column: 'left',
        title: 'platform_modules',
        links: [
          {
            title: 'triage',
            description: 'triage',
            to: '/solutions/triage',
            image: 'navi_icon_05',
          },
          {
            title: 'intake',
            description: 'intake',
            to: '/solutions/intake',
            image: 'navi_icon_06',
          },
          // {
          //   title: 'followup',
          //   additionalInfo: 'Coming soon',
          //   description: 'followup',
          //   image: 'navi_icon_08',
          // },
          {
            title: 'api',
            to: '/solutions/infermedica-api',
            description: 'api',
            image: 'navi_icon_07',
            withSeperator: true,
          },
        ],
      },
      {
        column: 'right',
        title: 'intelligent_core',
        links: [
          {
            to: '/solutions/infermedica-api',
            title: 'infer-api',
          },
          {
            to: '/medical-knowledge-base',
            title: 'base',
          },
          {
            to: '/inference-engine',
            title: 'engine',
          },
          {
            to: '/trusted-ai',
            title: 'infermedica-ai',
          },
        ],
      },
      {
        column: 'right',
        title: 'extension',
        links: [
          {
            to: '/features',
            title: 'feature',
          },
        ],
      },
      {
        column: 'right',
        title: 'clinical_validation',
        links: [
          {
            title: 'test_cases',
            to: '/test-cases',
          },
        ],
      },
    ],
  },
  {
    tabName: 'network',
    sections: [
      {
        column: 'left',
        title: 'partners',
        links: [
          {
            title: 'partners',
            to: '/partner-program',
            description: 'partners',
            image: 'navi_icon_11',
          },
          {
            title: 'features',
            description: 'features',
            to: '/partners',
            image: 'navi_icon_partners_solutions',
          },
        ],
      },
    ],
  },
  {
    tabName: 'resources',
    sections: [
      {
        column: 'left',
        title: 'resources',
        links: [
          {
            title: 'publications',
            to: '/research-studies',
            description: 'publications',
            image: 'navi_icon_15',
          },
          {
            title: 'case_studies',
            to: '/case-studies',
            description: 'case_studies',
            image: 'navi_icon_10',
          },
          {
            title: 'patient_stories',
            to: '/patient-stories',
            description: 'patient_stories',
            image: 'navi_icon_16',
          },
        ],
      },
      {
        column: 'left',
        title: 'documentation',
        links: [
          {
            title: 'developer_portal',
            href: 'https://developer.infermedica.com/',
            description: 'developer_portal',
            image: 'navi_icon_09',
            newWindow: true,
          },
          {
            title: 'help_center',
            href: 'https://help.infermedica.com/',
            description: 'help_center',
            image: 'navi_icon_17',
            newWindow: true,
          },
        ],
      },
      {
        column: 'right',
        title: 'other',
        links: [
          {
            title: 'reports',
            to: '/reports',
          },
          {
            title: 'vlogs',
            to: '/vlogs',
          },
          {
            title: 'webinars',
            to: '/webinars',
          },
          {
            title: 'newsroom',
            to: '/newsroom',
          },
          {
            title: 'blog',
            to: '/blog',
          },
        ],
      },
    ],
  },
  {
    tabName: 'company',
    sections: [
      {
        column: 'left',
        title: 'connect_us',
        links: [
          {
            title: 'about',
            description: 'about',
            to: '/about',
            image: 'navi_icon_14',
          },
          {
            title: 'careers',
            description: 'careers',
            image: 'navi_icon_12',
            to: '/careers',
          },
          {
            title: 'events',
            description: 'events',
            to: '/events',
            image: 'navi_icon_events',
          },
          {
            title: 'press',
            description: 'press',
            image: 'navi_icon_press',
            to: '/press',
          },
          {
            title: 'contact',
            description: 'contact',
            to: '/contact',
            image: 'navi_icon_13',
          },
        ],
      },
    ],
  },
]

export default Routes
