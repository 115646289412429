<script lang="ts" setup>
import type {
  NavigationSection,
  NavigationTab,
  NavigationTabPayload,
} from '@/types'

import { NEW_PILL_NAMES } from '@/data/constants'

interface Props {
  currentTab: NavigationTab
  currentTabIndex: number
}

const store = useMainStore()
const currentPromoType = computed(
  () => store.promo?.story.content.promo_type || '',
)

const emit = defineEmits<{
  (e: 'change-tab-state', payload: NavigationTabPayload): void
}>()

const props = defineProps<Props>()

const columnsModifier = computed(() => {
  const numberOfSections = props.currentTab.sections?.length || 1

  if (
    numberOfSections >= 2 &&
    !props.currentTab.sections.every((section) => section.column === 'left')
  ) {
    return 'navigation-tab-panel--two-columns'
  }

  return 'navigation-tab-panel--one-column'
})

const setSectionClass = (section: NavigationSection) => {
  return section.column === 'left'
    ? 'navigation-tab-panel__section--left'
    : 'navigation-tab-panel__section--right'
}

const changeTab = () => {
  emit('change-tab-state', { event: { type: 'OPEN_NEXT' } })
}

const closePanel = () => {
  emit('change-tab-state', {
    event: {
      type: 'RESET',
    },
  })
}

const sectionsLeft = computed(() =>
  props.currentTab.sections?.filter((section) => section.column === 'left'),
)
const sectionsRight = computed(() =>
  props.currentTab.sections?.filter((section) => section.column === 'right'),
)
</script>

<template>
  <div
    v-show="currentTab.sections"
    role="tabpanel"
    :class="['navigation-tab-panel', columnsModifier]"
    @keydown.esc="closePanel"
    @mouseleave="closePanel"
  >
    <div v-show="sectionsLeft.length" class="padding-flow">
      <div
        v-for="section in sectionsLeft"
        :key="section.title"
        :class="['navigation-tab-panel__section', setSectionClass(section)]"
      >
        <p class="heading--6 navigation-tab-panel__title heading-6">
          {{ $t(`common.navigation.sections_names.${section.title}`) }}
        </p>
        <ul class="flow | navigation-tab-panel__list">
          <NavigationLink
            v-for="(link, index) in section.links"
            :key="index"
            :link="link"
            :is-new="
              link.title ===
              NEW_PILL_NAMES[currentPromoType as keyof typeof NEW_PILL_NAMES]
            "
            @close-tab="closePanel"
            @change-tab="changeTab"
          />
        </ul>
      </div>
    </div>
    <div v-show="sectionsRight.length" class="padding-flow">
      <div
        v-for="section in sectionsRight"
        :key="section.title"
        :class="['navigation-tab-panel__section', setSectionClass(section)]"
      >
        <h6 class="navigation-tab-panel__title heading-6">
          {{ $t(`common.navigation.sections_names.${section.title}`) }}
        </h6>
        <ul class="flow | navigation-tab-panel__list">
          <NavigationLink
            v-for="(link, index) in section.links"
            :key="index"
            :link="link"
            :is-new="
              link.title ===
              NEW_PILL_NAMES[currentPromoType as keyof typeof NEW_PILL_NAMES]
            "
            @close-tab="closePanel"
            @change-tab="changeTab"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navigation-tab-panel {
  @include shadow-flat-strong;

  --link-padding-top: 8px;
  --link-padding-bottom: 8px;
  --link-padding-inline: 12px;
  --padding-block-start: 24px;
  --padding-block-end: calc(32px - var(--link-padding-bottom));
  --padding-inline: 32px;

  $self: &;

  display: grid;
  overflow: hidden;
  position: absolute;
  z-index: 9998;
  left: var(--offset-x, 0);
  top: calc(100% - 8px);
  transform: translateX(-50%);
  background-color: var(--white);
  border-radius: var(--border-radius-extra-large);
  padding: var(--padding-block-end) 0;

  &__list {
    padding: 0 calc(var(--padding-inline) - var(--link-padding-inline));

    --space: calc(
      var(--gap-between, 24px) -
        (var(--link-padding-top) + var(--link-padding-bottom))
    );
  }

  &__title {
    padding: 0 var(--padding-inline);
    margin: 0;
    color: var(--cobalt-800);
    margin-bottom: calc(12px - var(--link-padding-top));
  }

  &__section {
    --space: 36px;

    &--right {
      overflow: hidden;

      --gap-between: 16px;
      --link-padding-top: 4px;
      --link-padding-bottom: 4px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: var(--paper-100);
        z-index: -1;
      }
    }
  }

  &--one-column {
    width: 355px;
  }

  &--first {
    width: 371px;

    &#{$self}--de {
      width: 385px;
    }
  }

  &:is(&--two-columns) {
    width: 636px;
    grid-template-columns: 355px 1fr;
  }
}
</style>
