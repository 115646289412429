<script lang="ts" setup>
import routes from '@/data/navigation-routes'
import type { NavigationTabPayload } from '@/types'

interface Props {
  navbarColor: string
}
const props = defineProps<Props>()

const {
  isTabSelected,
  changeTabState,
  setTabIndex,
  COMPONENT_ATTRS,
  currentIndex,
  selectedTab,
  isLastTab,
  isFirstTab,
  isOpen,
} = useTabs(routes, 'navigation')

const { locale } = useI18n()

const isGermanRoute = computed(() => locale.value === 'de')

const offsetX = ref({
  '--offset-x': '0',
})

const handleChangeState = ({ offset, event }: NavigationTabPayload) => {
  if (offset) {
    offsetX.value = {
      '--offset-x': offset,
    }
  }
  changeTabState(event)
}

const handleSkipNavigation = () => {
  const element = document.getElementById('main-content')
  element?.focus()
}

const closeNavigationPanel = (e: MouseEvent) => {
  if (!e?.relatedTarget) {
    return changeTabState({
      type: 'CLOSE',
    })
  }

  const floatingPromo = document.getElementById('floating-promo') as Element
  if (floatingPromo && floatingPromo.contains(e.relatedTarget as Element)) {
    return changeTabState({
      type: 'RESET',
    })
  }
}
const checkTabState = (index: number) => {
  return isOpen.value && index === currentIndex.value
}

const localePath = useLocalePath()

const { navbarStyle } = useNavbarStyle(props.navbarColor)
</script>

<template>
  <nav
    id="desktop-navigation"
    v-click-outside="closeNavigationPanel"
    class="navigation"
    :style="navbarStyle"
    @mouseleave="closeNavigationPanel"
  >
    <button
      type="button"
      class="navigation__hidden-link"
      @click="handleSkipNavigation"
    >
      Skip Navigation
    </button>
    <header class="navigation__header">
      <NavigationLogo
        class="navigation__logo"
        @mouseenter="changeTabState({ type: 'RESET' })"
      />
      <section class="navigation__tabs" aria-multiselectable="false">
        <ul id="navigation-tab-list" class="navigation__tabs" role="tablist">
          <NavigationHeaderItem
            v-for="(tab, index) in routes"
            :key="tab.tabName"
            :tab-name="tab.tabName"
            :data-target="COMPONENT_ATTRS.TAB_ITEM"
            :tab="tab"
            :index="index"
            :tab-index="setTabIndex(index)"
            :is-selected="isTabSelected(index)"
            :is-last-item="isLastTab"
            @change-tab-state="handleChangeState"
          />
        </ul>

        <div
          v-for="(tab, index) in routes"
          :id="`tab-panel-${index}`"
          :key="tab.tabName"
        >
          <NavigationTabPanel
            v-show="checkTabState(index)"
            :style="offsetX"
            :current-tab-index="currentIndex"
            :current-tab="selectedTab"
            :aria-hidden="isOpen"
            :data-test-id="`test-index-${currentIndex}`"
            :class="{
              'navigation-tab-panel--last': isLastTab,
              'navigation-tab-panel--first': isFirstTab,
              'navigation-tab-panel--de': isGermanRoute,
            }"
            @change-tab-state="handleChangeState"
          />
        </div>
      </section>
      <div class="navigation__right">
        <NavigationLanguageSwitcher
          :navbarColor="navbarColor"
          class="navigation__lang-switcher"
          :tabindex="currentIndex === routes.length - 1 ? 0 : -1"
          @focus="changeTabState({ type: 'RESET' })"
          @mouseenter="changeTabState({ type: 'RESET' })"
        />
        <BaseButton
          id="navigation-cta-button"
          data-target="true"
          class="navigation__cta-button"
          small
          filled
          :title="$t('common.navigation.cta')"
          :to="localePath('/get-in-touch')"
          @focus="changeTabState({ type: 'RESET' })"
          @mouseenter="changeTabState({ type: 'RESET' })"
        >
          <template #text>
            {{ $t('common.navigation.cta') }}
          </template>
        </BaseButton>
      </div>
    </header>
  </nav>
</template>

<style lang="scss" scoped>
.navigation {
  --space: 16px;
  transition: background-color 0.2s;
  will-change: background-color;
  position: sticky;
  z-index: 9998;
  top: 0;
  display: none;
  height: var(--header-height);
  background-color: var(--navbar-bg-color);
  color: var(--navbar-text-color);
  width: 100%;

  @media (min-width: $breakpoint-lg) {
    display: flex;
  }
  &__logo {
    width: 214px;
  }

  &__hidden-link {
    position: absolute;
    background-color: rgba(white, 0.6);
    padding: 8px;
    z-index: 9999;
    top: 50%;
    transform: translate(-120%, -50%);
    transition: transform 150ms ease-in;

    &:focus {
      transform: translate(0, -50%);
    }
  }

  &__header {
    width: 100%;
    height: var(--header-height);
    font-size: var(--font-size-paragraph-s);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
  }

  &__tabs {
    display: flex;
    align-items: center;
    word-break: normal;
  }

  &__cta-button {
    display: block;
    margin-left: 22px;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 214px;
  }
}
</style>
