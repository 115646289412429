<script setup lang="ts">
import { getCurrentLocale } from '@/utils/helpers'

const route = useRoute()
const i18n = useI18n()
const isMobileNavOpen = ref(false)
const visibilityClass = computed(
  () => isMobileNavOpen.value && 'navigation-mobile__content--visible',
)

interface Props {
  navbarColor: string
}
const props = defineProps<Props>()

const { navbarStyle } = useNavbarStyle(props.navbarColor)

const language = getCurrentLocale(i18n)

const handleCloseNavigation = (path?: string) => {
  if (!path || route.path === `${language}${path}`) {
    setIsOpen(false)
  }
}

const setIsOpen = (payload: boolean) => {
  isMobileNavOpen.value = payload
}

watch(
  () => route.fullPath,
  () => {
    isMobileNavOpen.value = false
  },
)
watch(isMobileNavOpen, () => {
  document.documentElement.style.overflow = isMobileNavOpen.value
    ? 'hidden'
    : 'auto'
})
onUnmounted(() => {
  document.documentElement.style.overflow = 'auto'
})
</script>

<template>
  <nav class="navigation-mobile">
    <header class="navigation-mobile__header container" :style="navbarStyle">
      <NavigationLogo />
      <NavigationBurgerButton
        :aria-expanded="isMobileNavOpen.toString()"
        class="navigation-mobile__button"
        @click="setIsOpen(true)"
      />
    </header>
    <NavigationMobileContent
      v-if="isMobileNavOpen"
      id="primary-navigation-mobile"
      :is-open="isMobileNavOpen"
      :class="['navigation-mobile__content', visibilityClass]"
      @close="handleCloseNavigation"
    />
  </nav>
</template>

<style lang="scss" scoped>
.container {
  @media (min-width: $breakpoint-md) {
    --container-padding: 54px;
  }
}

.navigation-mobile {
  position: relative;
  background: var(--white);
  position: sticky;
  top: 0;
  z-index: 9998;

  @media (min-width: $breakpoint-lg) {
    display: none;
  }

  &__header {
    transition: background-color 0.2s;
    height: var(--header-height);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--navbar-bg-color);
    color: var(--navbar-text-color);
  }

  &__button {
    position: relative;
    padding: 15px 10px;
    transform: translateX(10px);
  }

  &__content {
    display: none;

    &--visible {
      display: block;
    }
  }
}
</style>
